<template>
  <v-app id="cust-app" style="background-color: #583480">
    <!------------------------- mobile app bar ----------------------- -->
    <!-- <v-navigation-drawer v-if="mobile" class="cust-bg-color-purple text-white" v-model="drawer" location="bottom" style="width: 100dvw" :style="[drawer ? { transform: `translateY(64px)` } : {}]" > -->
    <v-navigation-drawer v-if="mobile" class="cust-bg-color-purple text-white" v-model="drawer" location="bottom" touchless style="width: 100dvw" >
      <!-- scrim for drawer is forced on mobile by vuetify -->
      <v-list>
        <v-list-item :to="menu_item.link" class="px-7" v-for="(menu_item, menu_item_idx) in app_bar_menu" :key="menu_item_idx" >
          <v-list-item-title>{{ menu_item.display }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="mobile" id="id-app-bar" elevation="0" class="text-white px-0" :class="{ 'cust-app-bar': route.path != '/' }" style="width: 100dvw" >
      <template v-slot:prepend>
        <!-- v-toolbar__prepend has left margin of 10px  other component should be px-7 -->
        <router-link to="/">
          <v-img width="100" style="margin-left: 18px" src="./assets/Logos/img-interisland.png" ></v-img>
        </router-link>
      </template>
      <template v-slot:append>
        <v-app-bar-nav-icon id="nav-icon" @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>
      </template>
    </v-app-bar>

    <!------------------ mobile responsive divider ------------------>

    <v-app-bar v-if="!mobile" id="id-app-bar" elevation="0" class="text-white px-0" :class="{ 'cust-app-bar': route.path != '/' }" >
      <!-- <v-app-bar id="id-app-bar" elevation="0" class="text-white" :class="{'cust-app-bar': current_uri != '/', 'cust-app-bar-hero': current_uri == '/' }"> -->
      <template v-slot:prepend>
        <!-- v-toolbar__prepend has left margin of 10px -->
        <router-link to="/"> <v-img width="100" style="margin-left: 50px" src="./assets/Logos/img-interisland.png" ></v-img>
        </router-link>
      </template>
      <template v-slot:append>
        <div class="d-flex justify-end px-0" style="margin-right: 34px">
          <router-link :to="menu_item.link" v-for="(menu_item, menu_item_idx) in app_bar_menu" :key="menu_item_idx" >
            <div class="mx-4 c-nav-item">{{ menu_item.display }}</div>
          </router-link>
        </div>
      </template>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer class="pa-0 ma-0 w-100">
      <CustFooter />
    </v-footer>
  </v-app>
</template>

<script>
// Components
import CustFooter from "./components/CustFooter.vue";
import { useRoute } from "vue-router";
import { ref, watch } from "vue";
import { useDisplay } from "vuetify";
export default {
  name: "App",
  components: {
    CustFooter,
  },
  setup() {
    // vuetify display mobile resonsive
    const { mobile } = useDisplay();
    const drawer = ref(false);

    const route = useRoute();
    // route.path == "/" for home page.
    const current_uri = ref(route.path);
    const app_bar_menu = [
      {
        display: "CORPORATE PROFILE",
        link: "/CorpProfile",
      },
      {
        display: "OUR BRANDS",
        link: "/OurBrands",
      },
      {
        display: "OUR INITIATIVES",
        link: "/OurInitiatives",
      },
      {
        display: "CONTACT",
        link: "/Contact",
      },
    ];

    // watch(route.path,(new_path) => {
    //   if(new_path=="/"){
    //     appBarScrollTrigger.enable()
    //   } else {
    //     appBarScrollTrigger.disable()
    //   }
    // })

    return {
      // UI layout
      app_bar_menu,
      route,
      // mobile responsive
      mobile,
      drawer,
    };
  },
};
</script>

<style>
/* -------------------------------------------------------------- */
:root {
  --c-purple: #583480;
  --c-dark-purple: #663399;
  --c-light-purple: #8e6baf;
  --c-teal: #56ece3;
}

.font-teal {
  color: var(--c-teal) !important;
}
.font-purple {
  color: var(--c-purple) !important;
}

/* This is stack implementation via grid template area */
.cust-grid-stack-ctnr {
  display: grid;
  width: 100%;
  place-items: center;
  grid-template-areas: "inner-div";
}
.cust-grid-stack-item {
  height: 100%;
  width: 100%;
  grid-area: inner-div;
}

/* This is stack implementation via positioning */
.cust-hero-ctnr {
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
.cust-hero-item {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
/* ----------------------------------------------- */
strong {
  color: var(--c-purple); /* color to be removed. */
  font-size: 1.5em; /* size to be removed. */
}
em {
  color: var(--c-teal);
  font-weight: bold; /* bold to be removed. */
  font-style: normal;
}
.cust-bg-color-purple strong {
  color: white;
}
.cust-bg-color-white strong {
  color: var(--c-purple);
}
/* page margin should appear around ma-8, 32px around x axis */
.router-link-exact-active,
a {
  color: inherit;
  background: inherit;
  text-decoration: none;
}

.cust-app-bar-hero {
  background-color: transparent !important;
}
.cust-app-bar {
  background-color: var(--c-purple) !important;
}

html,
.cust-bg-color-purple {
  background-color: var(--c-purple) !important;
}
.cust-bg-color-teal {
  background-color: var(--c-teal) !important;
}
.cust-bg-color-white {
  background-color: white !important;
}
html {
  font-family: "Poppins" !important;
  font-size: 17px;
}
/* ------------------------------------------------------------------------ */
/* ------------------ standardized font size by location ------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------ app bar ------------------------ */
.c-nav-item {
  color: white;
  font-size: 15px;
}
.c-nav-item:hover {
  color: var(--c-teal);
}
/* ------------------------ app bar ------------------------ */
.c-mouse-over-teal:hover {
  color: #56ece3;
}
.c-mouse-over-white:hover {
  color: #ffffff;
}
/* ------------------------ homepage ------------------------ */
.mobile-home-header-div {
  line-height: 1.2em;
}
.mobile-home-header-em {
  font-size: 22px;
  color: var(--c-teal) !important;
}
.mobile-home-header {
  font-size: 22px;
}
.mobile-home-header-text {
  font-size: 12px;
  font-weight: lighter;
}
.mobile-home-ourbrands-header-div {
  line-height: 1.2em;
}
.mobile-home-ourbrands-header-em {
  font-size: 22px;
  color: var(--c-teal) !important;
}
.mobile-home-ourbrands-header {
  font-size: 22px;
}
.mobile-home-ourbrands-header-text {
  font-size: 12px;
  font-weight: lighter;
}
.mobile-home-cp-header-em {
  font-size: 22px;
  color: var(--c-teal) !important;
}
.mobile-home-cp-text {
  font-size: 12px;
}
.c-home-header-div {
  line-height: 1.8em;
}
.c-home-header-em {
  color: var(--c-teal);
  font-size: 24pt;
  font-weight: normal;
}
.c-home-header {
  font-size: 24pt;
  font-weight: normal;
  color: white;
}
.mobile-home-brands-title-em {
  font-size: 22px;
  color: var(--c-teal) !important;
  line-height: 1.2em;
}
.mobile-home-brands-title {
  font-size: 22px;
  line-height: 1.2em;
}
.c-home-brands-title-div {
  line-height: 1.8em;
}
.c-home-brands-title-em {
  color: var(--c-teal) !important;
  font-size: 24pt;
}
.c-home-brands-title {
  font-size: 24pt;
  color: var(--c-purple) !important;
}
.mobile-home-brands-logo {
  width: 100%;
}
.mobile-home-brands-logo-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.c-home-brands-logo {
  width: 100%;
}
.c-home-brands-logo-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.c-home-cp-title {
  font-size: 24pt;
  color: var(--c-teal) !important;
}
.c-home-featured-title-div {
  line-height: 1.5em;
}
.c-home-featured-title-em {
  color: var(--c-teal) !important;
  font-size: 24pt;
}
.c-home-featured-title {
  font-size: 24pt;
  color: var(--c-purple) !important;
}
.c-home-feature-post {
  color: var(--c-purple);
}
.c-home-feature-post:hover,
.c-home-feature-post:hover .c-home-feature-post-summary{
  color: white;
  background-color: var(--c-purple);
}
.c-home-feature-post:hover strong,
.c-home-feature-post:hover .v-icon {
  color: var(--c-teal);
}
.c-home-feature-post-picbox {
  flex-grow: 0;
  flex-shrink: 0;
  width: 30%;
  aspect-ratio: 4/3;
}
.c-home-feature-post-txtbox {
  flex-grow: 1;
  flex-shrink: 1;
}
.c-home-feature-post-summary {
  color: black;
}
.c-home-feature-post-gal-img-div {
  width: calc((100dvw - 120px + 24px)/8 - 3px);
  padding: 24px 12px 0px 12px;
}
/* ------------------------ corporate profile page ------------------------ */
.mobile-corp-profile-header-div {
  line-height: 1.1em;
}
.mobile-corp-profile-header-em {
  font-size: 22px;
  color: var(--c-teal) !important;
}
.mobile-corp-profile-header {
  font-size: 22px;
}
.mobile-corp-profile-text {
  font-size: 12px;
  font-weight: lighter;
  color: var(--c-purple);
}
.mobile-corp-profile-snapshot-kpi {
  font-size: 20px;
  line-height: 150%;
}
.mobile-corp-profile-snapshot-category {
  font-size: 16px;
  font-weight: bold;
  color: var(--c-purple);
  /* line-height: 60%; */
}
.mobile-corp-profile-snapshot-text {
  font-size: 12px;
  color: var(--c-purple);
}
.mobile-corp-profile-milestone-date {
  font-size: 14px;
  font-weight: bold;
  color: white;
}
.mobile-corp-profile-milestone-text {
  font-size: 12px;
  color: white;
}
.mobile-corp-profile-ourcapability-text {
  font-size: 12px;
}
.c-cp-header {
  font-size: 48px;
  line-height: 0.6em;
  font-weight: bolder;
  color: white;
}
.c-cp-header:first-child{
  line-height: 1em;
}
.c-cp-group-title-em {
  font-size: 40px;
  font-weight: bolder;
  color: var(--c-teal) !important;
}
.c-cp-group-title {
  font-size: 40px;
  font-weight: bolder;
  color: var(--c-purple) !important;
}
.c-cp-group-snapshot-header {
  font-size: 40px;
  line-height: 0.6em;
  font-weight: bolder;
  color: var(--c-purple);
}
.c-cp-group-snapshot-header:first-child {
  line-height: 1em;
}
.c-cp-group-milestone-header {
  font-size: 40px;
  line-height: 0.8em;
  font-weight: bolder;
  color: white;
}
.c-cp-group-milestone-header:first-child {
  line-height: 1em;
}
.c-prestige-awards em {
  color: white;
}
.c-prestige-awards p {
  color: var(--c-purple);
  font-size: 20px;
}
.c-prestige-awards {
  /* use comma for multiple backgrounds going from front to back */
  background: #d0d3da;
}
.c-prestige-awards-logo {
  width: 130px;
  max-height: 100%;
  /* margin-right: 50px; */
}
/* ----------------------------------------------------------------- */
.v-overlay__scrim {
  opacity: 0.4 !important; /* Vuetify default value is 0.32. Opacity API not working. */
}
.c-cp-exe-ctnr {
  width: 261px;
  margin: 40px;
}
.m-cp-exe-name {
  color: var(--c-teal);
  font-weight: bolder;
  font-size: 22px;
}
.m-cp-exe-title {
  color: white;
  font-size: 12px;
}
.c-cp-exe-name {
  color: var(--c-teal);
  font-weight: bolder;
  font-size: 22px;
}
.c-cp-exe-title {
  color: white;
}
.c-cp-exe-pic-transition {
  background-position: center;
  transition: background-image 0.5s ease-in-out;
  width: 261px;
  aspect-ratio: 261 / 297 ;
  margin-bottom: 20px;
}
/* Elvis */
.c-cp-exe-pic-transition-0 {
  background-image: url('~@/assets/Portraits/Protrait_Elvis.png');
}
.c-cp-exe-pic-transition-0:hover {
  background-image: url('~@/assets/Portraits/Protrait_Elvis_Real.png');
}
/* Amelia */
.c-cp-exe-pic-transition-1 {
  background-image: url('~@/assets/Portraits/Protrait_Amelie.png');
}
.c-cp-exe-pic-transition-1:hover {
  background-image: url('~@/assets/Portraits/Protrait_Amelie_Real.png');
}
/* Elvin */
.c-cp-exe-pic-transition-2 {
  background-image: url('~@/assets/Portraits/Protrait_Elvin.png');
}
.c-cp-exe-pic-transition-2:hover {
  background-image: url('~@/assets/Portraits/Protrait_Elvin_Real.png');
}
/* Angeline */
.c-cp-exe-pic-transition-5 {
  background-image: url('~@/assets/Portraits/Protrait_Angeline.png');
}
.c-cp-exe-pic-transition-5:hover {
  background-image: url('~@/assets/Portraits/Protrait_Angeline_real.png');
}
/* Matt */
.c-cp-exe-pic-transition-4 {
  background-image: url('~@/assets/Portraits/Protrait_Matt.png');
}
.c-cp-exe-pic-transition-4:hover {
  background-image: url('~@/assets/Portraits/Protrait_Matt_real.png');
}
/* Yie Sze */
.c-cp-exe-pic-transition-6 {
  background-image: url('~@/assets/Portraits/Protrait_SzeYi.png');
}
.c-cp-exe-pic-transition-6:hover {
  background-image: url('~@/assets/Portraits/Protrait_SzeYi_Real.png');
}
/* Lay Kheng */
.c-cp-exe-pic-transition-3 {
  background-image: url('~@/assets/Portraits/Protrait_LayKheng.png');
}
.c-cp-exe-pic-transition-3:hover {
  background-image: url('~@/assets/Portraits/Protrait_LayKheng_real.png');
}
/* Josten */
.c-cp-exe-pic-transition-7 {
  background-image: url('~@/assets/Portraits/Protrait_Josten.png');
}
.c-cp-exe-pic-transition-7:hover {
  background-image: url('~@/assets/Portraits/Protrait_Josten_real.png');
}
/* ----------------------------------------------------------------- */

.c-cp-exe-dialog-description {
  color: black;
  height: 100%;
}
.c-cp-exe-dialog-fullname {
  color: var(--c-dark-purple);
  font-weight: bolder;
  font-size: 30px;
  line-height: 50px;
}
.c-cp-exe-dialog-title {
  color: var(--c-dark-purple);
  font-size: 16px;
  line-height: 20px;
}
.c-cp-exe-dialog-footer {
  background-color: #56ece3;
  color: white;
}
.c-cp-exe-dialog-footer-nav {
  padding: 12px 20px;
}
.c-cp-exe-dialog-footer-nav:hover {
  cursor: pointer;
}
.mobile-prestige-awards {
  /* use comma for multiple backgrounds going from front to back */
  background:#d0d3da;
}
.mobile-prestige-awards-header {
  font-size: 22px !important;
  /* font-weight: bolder; */
  color: var(--c-purple);
}
.mobile-prestige-awards em {
  color: white !important;
}
.mobile-prestige-awards p {
  color: var(--c-purple);
  font-size: 12px;
}
.mobile-prestige-awards-logo {
  width: 110px;
  margin-right: 25px;
  margin-bottom: 25px;
}
.mobile-prestige-awards-logo2 {
  object-fit: contain;
  max-width: 100%;
  max-height: 130px;
}
/* ------------------------ our brands page ------------------------ */
.mobile-ourbrands-header-div {
  line-height: 1.2em;
}
.mobile-ourbrands-header-em {
  font-size: 22px;
  color: var(--c-teal) !important;
  text-transform: uppercase;
}
.mobile-ourbrands-header {
  font-size: 22px;
  text-transform: uppercase;
}
.mobile-ourbrands-text {
  font-size: 12px;
  font-weight: lighter;
}
.c-ourbrands-logo-banner-icon:hover {
  cursor: pointer;
}
.c-ourbrands-title-em {
  font-size: 40px;
  font-weight: bolder;
  color: var(--c-teal) !important;
  line-height: 0.6em;
}
.c-ourbrands-title {
  font-size: 40px;
  font-weight: bolder;
  color: white !important;
  line-height: 0.6em;
}
/* ------------------------ our Initiatives page ------------------------ */
.mobile-our-initiative-header-div {
  line-height: 1em;
}
.mobile-our-initiative-header-em {
  font-size: 22px;
  color: var(--c-teal) !important;
}
.mobile-our-initiative-header {
  font-size: 22px;
}
.mobile-our-initiative-text {
  font-size: 12px;
  font-weight: lighter;
}
.mobile-our-initiative-post-radio {
  font-size: 10px !important;
}
.mobile-our-initiative-post-radio .v-label {
  font-size: inherit !important;
  margin-left: 2px;
  margin-right: 5px;
}
.mobile-our-initiative-post-radio .v-selection-control__wrapper{
  width: 1em !important;
  margin:2px;
}
.mobile-our-initiative-post-text {
  color: var(--c-purple);
  font-size: 12px;
}
.c-our-initiative-radio {
  color: var(--c-dark-purple);
  font-weight: bolder;
  margin-right: 30px;
}
.c-our-initiative-radio:last-of-type {
  margin-right: 0px;
}
.c-our-initiative-radio .v-label {
  opacity: 1;
}
/* ------------------------ contact list page ------------------------ */
.mobile-contact-list-header-div {
  line-height: 1em;
}
.mobile-contact-list-header-em {
  font-size: 22px;
  color: var(--c-teal) !important;
}
.mobile-contact-list-header {
  font-size: 22px;
}
.mobile-contact-list-header-email {
  font-size: 12px;
  color: var(--c-teal) !important;
  text-decoration: underline;
}
.mobile-contact-list-header-text {
  font-size: 12px;
}
.mobile-contact-list-office-name-bolder {
  font-weight: bold;
  font-size: 16px;
}
.mobile-contact-list-office-name {
  font-size: 16px;
}
.mobile-contact-list-office-address {
  font-size: 12px;
}
.mobile-contact-list-office-tel {
  color: var(--c-purple) !important;
}
.mobile-contact-list-btn-apply {
  display: inline-block;
  font-size: 1em;
  line-height: 1em;
  height: 2em !important;
  padding: 0.5em 1em;
}
.c-contact-list-main-office-name {
  font-size: 20px;
  font-weight: 700;
}
.c-contact-list-office-name {
  font-size: 20px;
  font-weight: 600;
}
.c-contact-list-office-address {
  font-size: 16px;
}
/* .c-btn-apply {
  background-color: white;
  color: var(--c-dark-purple);
  font-size: 14px;
  height: 2rem;
  cursor: pointer;
} */
/* ------------------------ contact form page ------------------------ */
.mobile-contact-form-header-em {
  font-size: 28px;
  color: var(--c-teal) !important;
}
.mobile-contact-form-header {
  font-size: 28px;
}
.mobile-contact-form-address-text p{
  font-size: 12px;
  color: var(--c-purple) !important;
}
.mobile-contact-form-section-header-em {
  font-size: 14px;
  color: var(--c-teal) !important;
}
.mobile-contact-form-section-header {
  font-size: 14px;
}
.mobile-contact-form-field-text, .mobile-contact-form-field-text * {
  color: var(--c-purple) !important;
}
/* ------------------------------------------------------------------- */
/* ------------------------ contact form page ------------------------ */
.c-privacy-terms-header {
  font-size: 48px;
  line-height: 1em;
  font-weight: bolder;
  color: white;
  margin-bottom: 100px;
}

.c-privacy-terms-text {
  color: white;
  font-weight:lighter;
  white-space: pre-wrap;
}
.c-privacy-terms-text ul,
.c-privacy-terms-text p {
  margin-bottom: 1rem;
}
/* ------------------------------------------------------------------- */
.cust-header-title-row-1 {
  font-size: 48px;
  line-height: 1em;
}
.cust-header-title-row-n {
  font-size: 48px;
  line-height: 28px;
}
.cust-section-title-row-1 {
  font-size: 40px;
  line-height: 1em;
}
.cust-section-title-row-n {
  font-size: 40px;
  line-height: 30px;
}

/* ------------------ standardized font size by size ------------------ */
.cust-ft-small {
  font-size: 12px;
}
.cust-ft-l {
  font-size: 32px;
}
.cust-ft-xl {
  font-size: 40px;
}
/* ------------------ standardized font weight ------------------ */
.cust-ft-semi-bold {
  font-weight: 600;
}
.cust-ft-bold {
  font-weight: 700;
}
/* ------------------ color gliding button ------------------ */
/* create a 200% width background with distinct colors and then move it 100% in one transition */
/* meanwhile there is font color change too with a little delay to start. */
.btn-w-anime {
  background-image: linear-gradient(to bottom, #fff 0% 50%, var(--c-dark-purple) 50% 100%);
  /* background-size y value is given extra 2% to eliminate accidental white edge due to px rounding by the browser. */
  background-size: 100% 202%;
  transition: all 0.3s ease;
  color: black;
  font-size: 14px;
  border: solid 1px #666666;
  height: 2rem;
  cursor: pointer;
}

.btn-w-anime:hover {
  /* background-position-y positive goes down, negative goes up.
  The offset of the given background image's vertical position relative to the container. A value of 0% means that the top edge of the background image is aligned with the top edge of the container, and a value of 100% means that the bottom edge of the background image is aligned with the bottom edge of the container, thus a value of 50% vertically centers the background image. */
  background-position-y: 100%;
  color: white;
}

.btn-blue {
  background-color: var(--c-teal);
  color: var(--c-dark-purple);
  font-size: 14px;
  /* border: solid 1px #666666; */
  height: 2rem;
  cursor: pointer;
  padding-right: 24px !important;
  padding-left: 24px !important;
  border-radius: 9999px !important;
}

.btn-white {
  background-color: white;
  color: var(--c-dark-purple);
  font-size: 14px;
  /* border: solid 1px #666666; */
  height: 2rem;
  cursor: pointer;
  padding-right: 24px !important;
  padding-left: 24px !important;
  border-radius: 9999px !important;
}

.btn-purple {
  background-color: var(--c-light-purple);
  color: white;
  font-size: 14px;
  /* border: solid 1px #666666; */
  height: 2rem;
  cursor: pointer;
  padding-right: 24px !important;
  padding-left: 24px !important;
  border-radius: 9999px !important;
}

.btn-w-anime-blue {
  background-image: linear-gradient(to bottom, var(--c-teal) 0% 50%, var(--c-dark-purple) 50% 100%);
  background-size: 100% 200%;
  transition: all 0.3s ease;
  color: var(--c-dark-purple);
  font-size: 14px;
  border: solid 1px #666666;
  height: 2rem;
  cursor: pointer;
}
.btn-w-anime-blue:hover {
  background-position-y: 100%;
  color: white;
}
/* ------------------ miscellaneous ------------------ */
.c-prevent-select {
  /* prevent user from selecting the text */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.c-w-screen {
  /* not in use */
  width: 100dvw;
}
img {
  display: block;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.mobile-paragraph-spacing {
  padding-bottom: 1rem;
}
/* -------------------- vuetify mod ------------------ */
.v-infinite-scroll__side {
  background-color: white;
}
.v-infinite-scroll-intersect {
  border: none;
}
</style>