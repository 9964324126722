import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// --------------------------------------------------------
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
// --------------------------------------------------------
import vuetify from "./plugins/vuetify";

// ------------- load firebase configuration ------------------
import {firebaseConfig} from "../config_cms"
// -------------------------------------------------------------------

export const firebase_app = initializeApp(firebaseConfig);
const fb_firestoreDB = getFirestore(firebase_app);
const fb_storage = getStorage(firebase_app);
const fb_funcs = getFunctions(firebase_app);
const fb_analytics = getAnalytics(firebase_app); // Google Analytics
export { fb_firestoreDB, fb_storage, fb_analytics, fb_funcs };


createApp(App).use(router).use(store).use(vuetify).mount("#app");