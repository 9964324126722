import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {title: "Home - Interisland Group"}
  },
  {
    path: "/CorpProfile",
    name: "CorpProfile",
    component: () => import("../views/CorpProfile.vue"),
    meta: {title: "Corporate Profile - Interisland Group"}
  },
  {
    path: "/OurBrands",
    name: "OurBrands",
    component: () => import("../views/OurBrands.vue"),
    meta: {title: "Our Brands - Interisland Group"}
  },
  {
    path: "/OurInitiatives",
    name: "OurInitiatives",
    component: () => import("../views/OurInitiatives.vue"),
    meta: {title: "Our Initiatives - Interisland Group"}
  },
  {
    path: "/Contact",
    name: "ContactList",
    component: () => import("../views/ContactList.vue"),
    meta: {title: "Contact - Interisland Group"}
  },
  // {
  //   path: "/ContactUs",
  //   name: "ContactForm",
  //   component: () => import("../views/ContactForm.vue"),
  // },
  {
    path: "/PrivacyTerms",
    name: "PrivacyTerms",
    component: () => import("../views/PrivacyTerms.vue"),
    meta: {title: "Privacy Terms - Interisland Group"}
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition){
    // if(to.hash){
    //   // return {el: to.hash, top: 0, behavior: 'smooth'}
    //   return {selector: to.hash}
    // } else {
    //   // new page start from the top
    //   return {top: 0}
    // }
    // --------------------------------------
    // new page start from the top
    return {top: 0}
  },
  
});

router.afterEach((to, from) => {
  document.title = to.meta.title || "Interisland Group"
})

export default router;
