// ------------ Inter Island Website ------------
// ------------- PROD ------------------------
/* 
  firestore location: Singapore
*/

export const firebaseConfig = {
  // copy all these lines from "Add Firebase to your web app" setup guide.
  apiKey: "AIzaSyCdls3CuHduiKGsTIISmYMGAw9e0ibfCaI",
  authDomain: "interisland-front-end-prod.firebaseapp.com",
  projectId: "interisland-front-end-prod",
  storageBucket: "interisland-front-end-prod.appspot.com",
  messagingSenderId: "719689799898",
  appId: "1:719689799898:web:9be29193f0f93662066de3",
  measurementId: "G-9HHWB56118" // Google Analytics
}

// standard system fields for email of new contact form:
export const client_id = "interIsland_prod"
export const client_name = "InterIsland Group"
export const client_emails = ["hello@interislandgroup.com"]