<template>
  <v-container fluid class="cust-bg-color-purple text-white py-15 ma-0">
    <v-row class="d-flex justify-space-evenly">
      <v-col cols="10" md="3">
        <strong>CORPORATE PROFILE</strong>
        <router-link :to="link.link" v-for="(link, link_idx) in corp_profiles" :key="link_idx" >
          <p class="text-body-2 c-mouse-over-teal">{{link.display}}</p>
        </router-link>

        <div style="height:2rem;"></div>

        <strong>OUR INITIATIVES</strong>

        <router-link :to="link.link" v-for="(link, link_idx) in initiatives" :key="link_idx" >
          <p class="text-body-2 c-mouse-over-teal">{{link.display}}</p>
        </router-link>
      </v-col>

      <v-col cols="10" md="3">
        <strong>OUR BRANDS</strong>
        <router-link :to="link.link" v-for="(link, link_idx) in our_brands" :key="link_idx" >
          <p class="text-body-2 c-mouse-over-teal">{{link.display}}</p>
        </router-link>
      </v-col>

      <v-col cols="10" md="3">
        <strong>CONTACT</strong>
        <br>
        <p class="text-body-2">INTERISLAND GROUP (MAIN HQ)</p>
        <p class="text-body-2">116 Middle Road ICB Enterprise House #03-01</p>
        <p class="text-body-2">Singapore 188972</p>
        <div style="height:1rem;"></div>
        <p class="text-body-2">t: +65 6535 7338</p>
        <p class="text-body-2">e: <em><a href="mailto:info@interislandgroup.com" class="c-mouse-over-teal">hello@interislandgroup.com</a></em></p>
        <div style="height:2rem;"></div>
        <a href="\PrivacyTerms">
          <strong style="line-height: 1rem;" class="c-mouse-over-teal">TERMS & CONDITIONS</strong> <br>
          <strong style="line-height: 1rem;" class="c-mouse-over-teal">PRIVACY POLICY</strong>
        </a>
        <div style="height:2rem;"></div>
        <div style="left: -0.8rem; position:relative;">
          <v-btn class="c-mouse-over-teal"
            v-for="icon in social_media_icons"
            :key="icon.icon"
            :icon="icon.icon"
            variant="text"
            :href="icon.url"
          ></v-btn>
        </div>
        <p class="text-body-2">@ 2024 InterIsland Group. All Right Reserved.</p>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CustFooter",
  setup() {
    const corp_profiles = [
      {
        display: "About InterIsland Group",
        link: "/CorpProfile#cp-about"
      },
      {
        display: "Group Snapshot",
        link: "/CorpProfile#cp-group-snapshot"
      },
      {
        display: "Group Milestones",
        link: "/CorpProfile#cust-hori-scroll-cntr1"
      },
      {
        display: "Our Capabilities",
        link: "/CorpProfile#cp-our-expertise"
      },
      {
        display: "Prestige Awards",
        link: "/CorpProfile#cp-prestige-awards"
      },
      {
        display: "Team & Leadership",
        link: "/CorpProfile#cp-team-leadership"
      },
    ]
    const initiatives = [
      {
        display: "Events",
        link: "/OurInitiatives?selected_category=Event"
      },
      {
        display: "Corporate Social Responsibility",
        link: "/OurInitiatives?selected_category=Corporate Social Responsibility"
      },
      {
        display: "Media",
        link: "/OurInitiatives?selected_category=Media"
      }
    ]
    const our_brands = [
      {
        display: "Interisland Manpower",
        link: "/OurBrands?selected_brand=interisland manpower"
      },
      {
        display: "Interisland Consulting",
        link: "/OurBrands?selected_brand=interisland consulting"
      },
      {
        display: "i-Travels",
        link: "/OurBrands?selected_brand=iTravels"
      },
      {
        display: "Interisland Management",
        link: "/OurBrands?selected_brand=interisland mgmt"
      },
      {
        display: "i-Connect",
        link: "/OurBrands?selected_brand=iConnect"
      },
      {
        display: "Manpower Solution",
        link: "/OurBrands?selected_brand=Manpower Solution"
      },
      {
        display: "ASEANet Manpower",
        link: "/OurBrands?selected_brand=ASEANet Manpower"
      },
      {
        display: "ASEANet Distribution",
        link: "/OurBrands?selected_brand=ASEANet Distribution"
      },
      {
        display: "iCAFE",
        link: "/OurBrands?selected_brand=iCAFE"
      },
    ]
    const social_media_icons = [
        {icon:'mdi-facebook', url:"https://www.facebook.com/interislandgroup"},
        {icon:'mdi-linkedin', url:"https://www.linkedin.com/company/inter-island/mycompany/"},
        {icon:'mdi-youtube', url:"https://www.youtube.com/@interislandgroup2827"}
      ]
    return {
      corp_profiles,
      initiatives,
      our_brands,
      social_media_icons,
    }
  },
}
</script>

<style scoped>
strong {
  color: white;
  font-size: 1rem;
  line-height: 2.5rem;
}
em {
  color: #56ece3;
  font-weight: bold;
  font-style: normal;
}
</style>